<template>
  <el-collapse class="object-icons w-full" @change="openIcons">
    <el-collapse-item name="1">
      <template slot="title">
        <div
          class="object-icons__header flex items-center justify-between w-full p-4"
          :class="{ isOpen: isOpened }"
        >
          <div class="flex items-center gap-3" @click.stop>
            <svg
              class="cursor-pointer rounded-full isSelected"
              width="40"
              height="40"
              viewBox="0 -4 56 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <use
                :xlink:href="`/static/car_icon-new/cars.svg#${icon.name}`"
                :fill="icon.color"
              ></use>
            </svg>
            <div
              v-for="color in colors"
              :key="color.color"
              class="object-icons__header-color rounded-md flex justify-center items-center"
              :style="'background-color:' + color.color"
              @click.stop="setColor(color.label)"
            >
              <SelectedColor v-if="color.label === icon.color" />
            </div>
          </div>
          <ArrowDownIcon
            :class="['transform', isOpened ? '-rotate-90' : 'rotate-90']"
          />
        </div>
      </template>
      <div
        v-for="(item, index) in iconSort"
        :key="index"
        :class="{
          isSelected: icon.name === item
        }"
        class="icon-block rounded-full"
        @click="selectIcon(item)"
      >
        <svg
          class="cursor-pointer"
          width="50"
          height="50"
          viewBox="0 -4 56 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use
            :xlink:href="`/static/car_icon-new/cars.svg#${item}`"
            :fill="filledColor"
          ></use>
        </svg>
      </div>

      <template><!-- TMP fix styles icons -->
        <div class="icon-block__tmp">
          <svg
            width="50"
            height="50"
            viewBox="0 -4 56 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          ></svg>
        </div>
        <div class="icon-block__tmp">
          <svg
            width="50"
            height="50"
            viewBox="0 -4 56 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          ></svg>
        </div>
      </template>
    </el-collapse-item>
  </el-collapse>
</template>
<script setup>
import { computed, ref } from 'vue'

import ArrowDownIcon from '@/components/unitsNew/assets/icons/arrow-down.vue'
import SelectedColor from '@/components/unitsNew/assets/icons/selected-color.vue'
import iconSort from '@/utils/icons.js'

const props = defineProps({
  icon: {
    type: Object,
    default: () => ({})
  }
})

const emits = defineEmits(['onSetColor', 'onSetIcon'])

const isOpened = ref(false)

const colors = ref([
  {
    color: '#eb5757',
    label: 'red'
  },
  {
    color: '#1bb934',
    label: 'green'
  },
  {
    color: '#1585d8',
    label: 'blue'
  },
  {
    color: '#808080bf',
    label: 'grey'
  },
  {
    color: '#e68a2f',
    label: 'orange'
  }
])

const filledColor = computed(() => {
  return colors.value.find((color) => color.label === props.icon.color).color
})

const selectIcon = (icon) => {
  props.icon.name = icon

  emits('onSetIcon', icon)
}

const setColor = (color) => {
  props.icon.color = color

  emits('onSetColor', color)
}

const openIcons = () => {
  isOpened.value = !isOpened.value
}
</script>

<style lang="scss">
@import '../../../../assets/styles/Objects/ObjectIcons.scss';
</style>
