<template>
  <el-table
    v-infinite-scroll="load"
    :data="tableData"
    :row-class-name="tableRowClassName"
    :show-header="showHeader"
    :id="tableId"
    ref="tableRow"
    style="width: 100%"
    class="objects-table overflow-auto overflow-x-hidden"
    @row-click="$emit('onRowClick', $event)"
    @selection-change="$emit('onSelect', $event)"
    @select-all="$emit('onSelectAll', $event)"
  >
    <el-table-column
      v-if="options.hasCheckboxColumn"
      type="selection"
      min-width="3"
    />
    <el-table-column
      v-for="(item, idx) in titles"
      :key="idx"
      :property="item.prop"
      :label="item.label"
      :min-width="item.width"
      :header-align="item.headerAlign"
      :align="item.align"
    >
      <template slot="header">
        <div class="flex items-center" :class="[`justify-${item.align}`]">
          <span> {{ item.label }} </span>
          <SortIcon
            v-if="item.icon"
            :order-sort="item.orderSort"
            class="ml-1 cursor-pointer"
            @click.native="$emit('onSort', idx)"
          />
        </div>
      </template>
      <template slot-scope="scope">
        <div
          v-if="item.prop === 'sim_1_2'"
          class="flex flex-col text-xs font-normal leading-4 truncate"
        >
          <span v-for="(item, key) in scope.row[item.prop]" :key="key">
            {{ item }}
          </span>
        </div>

        <div v-else-if="item.prop === 'settings'" class="flex align-top gap-2">
          <!--          <div @click.stop.prevent="onAccess">-->
          <!--            <p class="cursor-pointer hovered-icon opacity-0 curiousBlue text-sm">Доступ</p>-->
          <!--          </div>-->
          <SettingsIcon
            class="cursor-pointer hovered-icon opacity-0"
            @click.stop.prevent="onAccess"
          />
        </div>
        <div v-else-if="item.prop === 'delete'">
          <DeleteIcon
            class="cursor-pointer hovered-icon"
            :class="{ recovered: options.recovered.includes(scope.row.id) }"
            @click.native.stop="
              $emit('onDelete', { item: scope.row, index: scope.$index })
            "
          />
        </div>
        <div
          v-else-if="item.prop === 'switch_actions'"
          class="flex justify-center gap-3"
        >
          <UnitsSwitch
            :value="scope.row.is_active"
            :disabled="
              scope.row.status !== linksStatus.ACTIVE_ON &&
              scope.row.status !== linksStatus.ACTIVE_OFF
            "
            @onChange="
              $emit('onChangeLinkStatus', { item: scope.row, value: $event })
            "
            @click.native.stop
          />
          <CopyBlueIcon
            :class="{ clicked: isCopyClicked }"
            @click.native.stop="copyToClipboard(scope.row)"
          />
          <SettingsIcon class="cursor-pointer hovered-icon opacity-0" />
        </div>
        <div
          v-else
          :class="[
            'mainTitleColor',
            'font-semibold',
            'truncate',
            ...item.class,
            {
              [`link-status-${scope.row.status}`]:
                scope.row.status && item.prop === 'statusText'
            },
            { recovered: options.recovered.includes(scope.row.id) }
          ]"
        >
          {{ getValue(item, scope.row) }}
        </div>
      </template>
    </el-table-column>
    <div
      slot="append"
      v-if="!tableData.length"
      class="flex flex-col gap-2 justify-center items-center greyToneSecond mt-10"
    >
      <emptyIcon v-if="empty.icon" />
      {{ empty.text }}
    </div>
  </el-table>
</template>

<script setup>
import { computed, defineAsyncComponent, ref, watch } from 'vue'
import { useI18n } from '@/hooks/useI18n'

import SortIcon from '@/components/unitsNew/assets/icons/sort.vue'
import SettingsIcon from '@/components/unitsNew/assets/icons/settings.vue'
import DeleteIcon from '@/components/unitsNew/assets/icons/delete.vue'
import UnitsSwitch from '@/components/unitsNew/components/UnitsSwitch.vue'
import CopyBlueIcon from '@/components/unitsNew/assets/icons/copy-blue.vue'
import { copyTextToClipboard } from '@/utils/helpers';

import linksStatus from '@/enums/newUnits/links-status.js'
import { useStore } from '@/store/store'

const tableRow = ref(null)
const $t = useI18n()
const emits = defineEmits(['onLoadData'])
const props = defineProps({
  titles: {
    type: Array,
    default: () => []
  },
  tableData: {
    type: Array,
    default: () => []
  },
  selectedItems: {
    type: Array,
    default: () => []
  },
  options: {
    type: Object,
    default: () => ({})
  },
  empty: {
    type: Object,
    default: () => ({})
  },
  showHeader: {
    type: Boolean,
    default: true
  },
  tableId: {
    type: String,
    default: 'table'
  },
  loadMore: {
    type: Boolean,
    default: false
  }
})

const Store = useStore()

const onAccess = () => {
  Store.commit('accessSettings/setIsAccessSettings', true)
}

const emptyIcon = computed(() => {
  return defineAsyncComponent(() =>
    import(`@/components/unitsNew/assets/icons/no-${props.empty.icon}.vue`)
  )
})

const isCopyClicked = ref(false)

const copyToClipboard = async (item) => {
  try {
    isCopyClicked.value = true

    await copyTextToClipboard(
      `https://${window.location.host}/locator?id=${item.id}`
    )

    setTimeout(() => {
      isCopyClicked.value = false
    }, 1000)
  } catch (err) {
    console.error('Failed to copy text: ', err)
  }
}

const getValue = (col, item) => {
  const property = col.prop.split('.')

  if (col.prop === 'userCount') {
    return item.users_count
  }

  if (col.prop === 'date_from' || col.prop === 'data_to') {
    if (item[col.prop] === $t('new_units.unspecified')) return item[col.prop]
    const date = item[col.prop].split(' ')

    if (date[1] && !col.fullTime) {
      return date[0]
    }

    return item[col.prop]
  }

  if (property.length === 1) return item[col.prop]

  let value = item

  property.forEach((key) => {
    value = value[key]
  })

  return value
}

const tableRowClassName = ({ row, rowIndex }) => {
  if (props.options.recovered.includes(row.id)) {
    return 'recovered'
  }

  if (rowIndex === 0) {
    return 'isTop'
  }
}

const load = () => {
  emits('onLoadData')
}

watch(
  () => props.loadMore,
  () => {
    props.selectedItems.forEach((val) => {
      tableRow.value.toggleRowSelection(val)
    })
  }
)
</script>

<style lang="scss">
@import '../assets/styles/UnitsTable.scss';
</style>
