<template>
  <el-form
    v-loading="loading"
    :model="currentLink"
    :ref="refs.linksValidation"
    class="links"
  >
    <div class="container overflow-auto flex flex-col gap-4">
      <div class="header relative flex justify-between items-center">
        <h1 class="font-bold text-2xl">
          {{ $t(`new_units.links.${title}`) }}
        </h1>
        <CloseIcon
          class="cursor-pointer tool-icon"
          @click.native="$emit('onBack')"
        />
      </div>
      <div class="input-container relative mt-5">
        <span class="input-label"> {{ $t('new_units.links.object') }}: </span>
        <el-form-item :rules="rules['unit.id']" prop="unit.id">
          <el-select
            v-model="currentLink.unit.id"
            :placeholder="$t('new_units.links.empty')"
            filterable
            class="w-full"
          >
            <el-option
              v-for="(item, index) in units"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </div>
      <div class="input-container relative">
        <el-form-item :rules="rules.date_from" prop="date_from">
          <UnitsDateTimePicker
            :date="currentLink.date_from"
            :label="$t('new_units.links.accessStart')"
            type="datetime"
            format="dd/MM/yyyy HH:mm"
            @onChange="currentLink.date_from = $event"
          />
        </el-form-item>
      </div>
      <div class="input-container relative">
        <el-form-item :rules="rules.date_to" prop="date_to">
          <UnitsDateTimePicker
            :date="currentLink.date_to"
            :label="$t('new_units.links.accessEnd')"
            type="datetime"
            format="dd/MM/yyyy HH:mm"
            @onChange="currentLink.date_to = $event"
          />
        </el-form-item>
      </div>
      <div class="input-container relative">
        <span class="input-label">
          {{ $t('new_units.links.description') }}:
        </span>
        <el-input
          v-model="currentLink.description"
          :placeholder="$t('new_units.links.placeholder')"
          :autosize="{ minRows: 2, maxRows: 4 }"
          type="textarea"
          class="w-full"
        />
      </div>
      <div class="link-divider relative input-container">
        <span class="input-label">{{ $t('new_units.links.link') }}: </span>
        <div class="flex gap-2">
          <el-input class="w-full" v-model="locatorLink" disabled />
          <div class="copy-icon" @click="copyToClipboard">
            <CopyIcon :class="{ clicked: isCopyClicked }" />
          </div>
        </div>
      </div>
      <button @click="submitLink" type="button" class="create w-full mt-4">
        {{ $t(`new_units.${title}`) }}
      </button>
    </div>
  </el-form>
</template>
<script setup>
import { computed, onBeforeMount, onUnmounted, ref } from 'vue'

import { createVuexHelpers } from 'vue2-helpers'
import { refs } from '@/components/unitsNew/helpers/index.js'
import { useI18n } from '@/hooks/useI18n'

const { useGetters, useMutations, useActions } = createVuexHelpers()

import CloseIcon from '../assets/icons/close.vue'
import CopyIcon from '@/components/unitsNew/assets/icons/copy.vue'
import UnitsDateTimePicker from '@/components/unitsNew/components/UnitsDateTimePicker.vue'
import { copyTextToClipboard } from '@/utils/helpers';

const { units } = useGetters('units', ['units'])

const { selectedLink } = useGetters('locators', ['selectedLink'])
const { SET_LOCATOR } = useMutations('locators', ['SET_LOCATOR'])

const $t = useI18n()
const emits = defineEmits(['onUpdate', 'onCreate'])

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  }
})

const currentLink = ref({
  id: '',
  unit: {
    id: ''
  },
  date_from: '',
  date_to: '',
  description: ''
})
const isCopyClicked = ref(false)

const rules = ref({
  'unit.id': [{ required: true, message: $t('new_units.links.errors.unit') }],
  date_from: [
    { required: true, message: $t('new_units.links.errors.access_start') }
  ],
  date_to: [
    { required: true, message: $t('new_units.links.errors.access_end') }
  ]
})

const submitLink = async () => {
  console.log('submit Link')
  await refs.linksValidation.value.validate((valid) => {
    if (valid) {
      currentLink.value.id
        ? emits('onUpdate', currentLink.value)
        : emits('onCreate', currentLink.value)
    }
  })
}
const copyToClipboard = async () => {
  try {
    isCopyClicked.value = true

    await copyTextToClipboard(`https://${locatorLink.value}`)

    setTimeout(() => {
      isCopyClicked.value = false
    }, 1000)
  } catch (err) {
    console.error('Failed to copy text: ', err)
  }
}

const locatorLink = computed(() => {
  return currentLink.value.id
    ? `${window.location.host}/locator?id=${currentLink.value.id}`
    : ''
})

const title = computed(() => {
  return currentLink.value.id ? 'edit' : 'create'
})

onBeforeMount(() => {
  if (selectedLink.value) {
    console.log(selectedLink.value)
    currentLink.value = selectedLink.value
  }
})

onUnmounted(() => {
  SET_LOCATOR(null)
})
</script>
<style lang="scss">
@import '../assets/styles/LinksForm.scss';
</style>
