<template>
  <div class="object-trailers flex">
    <div
      v-loading="listLoading"
      class="object-trailers__table-wrapper pt-5 w-7/12"
    >
      <div class="flex justify-between items-start px-5">
        <span class="text-base">{{ $t('new_units.trailers.title') }}</span>
        <UnitsAddButton active-tab="trailers" @click.native="addTrailer" />
      </div>
      <UnitsTable
        :table-data="data.list"
        :titles="data.titles"
        :options="data.options"
        :empty="emptyData"
        :key="tableRenderKey"
        table-id="table-id-trailers"
        class="object-trailers__table"
        @onRowClick="rowClick"
        @onDelete="deleteTrailer"
        @onSort="handleSort"
      />
      <UnitsDeletedRecovery
        v-if="data.options.recovered.length"
        :count="data.options.recovered.length"
        :active-tab="sections.TRAILERS"
        @onCancel="cancelDeletion"
        @onDeleteRecovered="finalDeletion"
      />
    </div>
    <UnitsTrailersDetails
      v-if="isNewTrailer || selectedTrailer"
      :trailer="selectedTrailer"
      :loading="selectedLoading"
      @onAddField="addCustomField"
      @onDeleteField="deleteCustomField"
      @onSave="saveTrailer"
    />
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, computed, watch, onMounted } from 'vue'

import { useI18n } from '@/hooks/useI18n'
import { createVuexHelpers } from 'vue2-helpers'
import { refs } from '@/components/unitsNew/helpers/index.js'
import { trailersApi } from '@/api'
import { sortHelper } from '../helpers/index.js'

import sections from '@/enums/newUnits/main-sections.js'

import UnitsTable from './UnitsTable.vue'
import UnitsAddButton from './UnitsAddButton.vue'
import UnitsTrailersDetails from './UnitsTrailersDetails.vue'
import UnitsDeletedRecovery from '@/components/unitsNew/components/UnitsDeletedRecovery.vue'

const { useGetters, useMutations, useActions } = createVuexHelpers()

const { allTrailers, searchedTrailers, trailerScrollTop } = useGetters(
  'trailers',
  ['allTrailers', 'searchedTrailers', 'trailerScrollTop']
)

const { CREATE, UPDATE } = useActions('trailers', ['CREATE', 'UPDATE'])

const $t = useI18n()
const instance = getCurrentInstance()
const emptyData = {
  icon: 'trailers',
  text: $t('new_units.trailers.no_trailers')
}

const listLoading = ref(false)
const selectedTrailer = ref(null)
const selectedLoading = ref(false)
const isNewTrailer = ref(false)

const deleteTime = 10000
let myTimeout = null
const tableRenderKey = ref(0)

const trailersTable = ref({
  list: [],
  titles: [
    {
      label: $t('new_units.trailers.table_columns.name'),
      prop: 'name',
      width: '40',
      headerAlign: 'left',
      align: 'left',
      icon: true,
      orderSort: '',
      class: ['max-w-textLong']
    },
    {
      label: $t('new_units.trailers.table_columns.width'),
      prop: 'width',
      width: '30',
      headerAlign: 'center',
      align: 'center',
      icon: true,
      sortType: 'number',
      orderSort: '',
      class: ['greyToneThird']
    },
    {
      label: $t('new_units.trailers.table_columns.code'),
      prop: 'code',
      width: '20',
      headerAlign: 'center',
      align: 'center',
      icon: true,
      sortType: 'number',
      orderSort: '',
      class: ['greyToneThird']
    },
    {
      label: '',
      prop: 'delete',
      width: '10',
      headerAlign: 'right',
      align: 'right'
    }
  ],
  options: {
    hasCheckboxColumn: false,
    recovered: []
  }
})

const data = computed(() => {
  return trailersTable.value
})

const handleSort = (idx) => {
  const orderSort = data.value.titles[idx].orderSort
  const prop = data.value.titles[idx].prop
  const sortType = data.value.titles[idx].sortType

  let sortedList = []

  if (orderSort === 'asc') {
    data.value.titles[idx].orderSort = 'desc'

    sortedList = data.value.list.sort((a, b) =>
      sortHelper.asc(a[prop], b[prop], sortType)
    )
  } else if (orderSort === 'desc') {
    data.value.titles[idx].orderSort = ''

    sortedList = allTrailers.value
  } else {
    data.value.titles[idx].orderSort = 'asc'

    sortedList = data.value.list.sort((a, b) =>
      sortHelper.desc(a[prop], b[prop], sortType)
    )
  }

  data.value.list = sortedList
  tableRenderKey.value += 1
}

const saveTrailer = async () => {
  try {
    let isValid = false

    refs.trailersValidation.value.validate((valid) => {
      isValid = valid
    })
    refs.trailersFieldsValidation.value.validate((valid) => {
      isValid = !valid ? valid : isValid
    })

    if (!isValid) return

    const isCreate = selectedTrailer.value.isNew

    selectedLoading.value = true

    isCreate
      ? await CREATE({
          ...selectedTrailer.value,
          id: null
        })
      : await UPDATE(selectedTrailer.value)

    trailersTable.value.list = [...allTrailers.value]

    if (isCreate) {
      selectedTrailer.value.id =
        trailersTable.value.list[trailersTable.value.list.length - 1].id
      selectedTrailer.value.isNew = false
    }

    isNewTrailer.value = false

    instance.proxy.$showSuccess(
      $t(`new_units.trailers.success.${isCreate ? 'approved' : 'updated'}`)
    )
  } catch (err) {
    instance.proxy.$showError(err.response.data.message)
  } finally {
    selectedLoading.value = false
  }
}

const rowClick = (trailer) => {
  let isValid = true

  if (isNewTrailer.value || selectedTrailer.value) {
    refs.trailersValidation.value.validate((valid) => {
      isValid = valid
    })
    refs.trailersFieldsValidation.value.validate((valid) => {
      isValid = !valid ? valid : isValid
    })
  }

  if (!isValid)
    return instance.proxy.$showError($t('new_units.trailers.errors.validation'))

  if (trailer.isNew) {
    selectedTrailer.value = trailer

    return
  }

  getSelectedTrailerData(trailer.id)
}

const addTrailer = () => {
  if (isNewTrailer.value)
    return instance.proxy.$showError($t('new_units.trailers.errors.approved'))

  isNewTrailer.value = true

  const newTrailer = {
    id: Math.random().toString(),
    name: '',
    width: '',
    code: '',
    custom_fields: [],
    description: '',
    isNew: true
  }

  trailersTable.value.list.push(newTrailer)

  selectedTrailer.value = newTrailer
}

const deleteTrailerHandler = async () => {
  trailersTable.value.options.recovered.forEach((id) => {
    const deletedTrailer = trailersTable.value.list.find(
      (newTrailer) => newTrailer.id === id
    )
    if (deletedTrailer.isNew) {} else {
      trailersApi.deleteTrailerById(
        id,
        () => {},
        (error) => {
          instance.proxy.$showError(error.response.data.message)
        }
      )
    }
  })
}

const finalDeletion = async () => {
  try {
    if (!trailersTable.value.options.recovered.length) return

    listLoading.value = true

    isNewTrailer.value = false

    await deleteTrailerHandler()

    trailersTable.value.list = trailersTable.value.list.filter(
      (item) => !trailersTable.value.options.recovered.includes(item.id)
    )

    instance.proxy.$showSuccess($t('new_units.trailers.success.delete'))
  } catch (err) {
    throw Error
  } finally {
    trailersTable.value.options.recovered = []
    selectedTrailer.value = trailersTable.value.list[0]
    listLoading.value = false

    clearTimeout(myTimeout)
  }
}

const deletionTimeout = () => {
  myTimeout = setTimeout(finalDeletion, deleteTime)
}

const deleteTrailer = (trailer) => {
  if (trailersTable.value.options.recovered.includes(trailer.item.id)) return
  // return instance.proxy.$showError(
  //   $t('new_units.trailers.errors.already_deleted')
  // )

  trailersTable.value.options.recovered.push(trailer.item.id)

  clearTimeout(myTimeout)
  deletionTimeout()
}

const cancelDeletion = () => {
  trailersTable.value.options.recovered.splice(0)

  clearTimeout(myTimeout)
}

const addCustomField = () => {
  selectedTrailer.value.custom_fields.push({
    localId: Math.ceil(Math.random() * (255 - 0) + 0),
    name: '',
    value: ''
  })
}

const deleteCustomField = (localId) => {
  selectedTrailer.value.custom_fields =
    selectedTrailer.value.custom_fields.filter(
      (field) => field.localId !== localId
    )
}

const getSelectedTrailerData = async (id) => {
  selectedLoading.value = true

  await trailersApi.getTrailer(
    id,
    (res) => {
      selectedTrailer.value = res.data

      selectedTrailer.value.custom_fields.map((field) => {
        field.localId = Math.ceil(Math.random() * (255 - 0) + 0)
      })

      selectedLoading.value = false
    },
    (err) => {
      return instance.proxy.$showError(err.response.data.message)
    }
  )
}

watch(searchedTrailers, (val) => {
  trailersTable.value.list = val
})

onMounted(async () => {
  if (allTrailers.value.length) {
    trailersTable.value.list = allTrailers.value.slice()
    await getSelectedTrailerData(allTrailers.value[0].id)
  }

  document.querySelector('#table-id-trailers').scrollTop =
    trailerScrollTop.value
})
</script>

<style lang="scss">
@import '../assets/styles/UnitsTrailers.scss';
</style>
