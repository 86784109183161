<template>
  <div class="object-save-modal flex flex-col items-center rounded-xl p-4">
    <CloseIcon
      class="cursor-pointer self-end mb-3"
      @click.native="$emit('onClose')"
    />
    <div class="object-save-modal__content flex flex-col items-center">
      <div
        class="object-save-modal__content__check rounded-full flex flex-col items-center p-4 mb-2"
      >
        <CheckIcon />
      </div>
      <span class="text-center">{{ $t('new_units.save_modal.message') }}</span>
      <el-button
        class="object-save-modal__content__continue mb-2 mt-7"
        @click="$emit('onContinue')"
        >{{ $t('new_units.save_modal.continue') }}</el-button
      >
      <el-button
        class="object-save-modal__content__close"
        @click="$emit('onClose')"
        >{{ $t('new_units.save_modal.close') }}</el-button
      >
    </div>
  </div>
</template>
<script setup>
import CloseIcon from '../assets/icons/close.vue'
import CheckIcon from '../assets/icons/check-mark.vue'

import { useI18n } from '@/hooks/useI18n'

const $t = useI18n()
</script>
<style lang="scss">
@import '../assets/styles/UnitsSaveModal.scss';
</style>
