<template>
  <div
    class="object-container absolute top-5 left-10 bottom-5 z-30 bg-white shadow py-4 rounded-xl ml-8"
  >
    <UnitsShowPage
      v-if="!modal.open"
      :is-modify="modal.open"
      :loading="loading"
      @onClose="handleClose"
      @onCreate="openModal('create', {})"
      @onEdit="openModal('edit', $event)"
      @onCreateGroup="openGroupModal('create')"
      @onCreateLink="linksModalTrigger(true, 'create')"
      @onDelete="handleDelete"
      @onShowHistory="handleShowHistory"
      @onChangeLinkStatus="submitLink('edit', $event)"
    />
    <ObjectsWrapper
      v-if="modal.open"
      @onBack="handleBack"
      @onSubmit="handleSaveModal(true)"
      @onShowHistory="handleShowHistory"
    />
    <UnitsOverlay :visible="isOverlay" />
    <UnitsSaveModal
      v-if="saveModal"
      @onContinue="handleContinue"
      @onClose="handleSaveModal"
    />
    <GroupsShowPage
      v-if="isGroups.open"
      :title="isGroups.type"
      :active-group="isGroups.activeGroup"
      @onBack="closeGroupModal"
      @onCreate="closeGroupModal('create')"
      @onEdit="closeGroupModal('edit')"
    />
    <LinksForm
      v-if="isLinks.open"
      :loading="linksLoading"
      @onUpdate="submitLink('edit', $event)"
      @onCreate="submitLink('create', $event)"
      @onBack="linksModalTrigger"
    />
    <Transition name="fading" mode="in-out">
      <BillingForm
        v-if="isBilling"
        @close="isBilling = false"
        @unblock="unblockCompany"
      />
    </Transition>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import _ from 'lodash'

const currentUrl = window.location.href

export default {
  name: 'UnitsList',

  props: {
    activeTabUnits: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isPayementReady: false,
      filterableData: [
        { key: 'Имя', value: 'name' },
        { key: 'IMEI', value: 'imei' },
        { key: 'Телефоны', value: 'phonenumber' },
        { key: 'Доп. поля', value: 'custom_fields' },
        { key: 'Имена датчиков', value: 'sensors.name' },
        { key: 'Типы датчиков', value: 'sensors.type.key' },
        { key: 'Параметр датчиков', value: 'sensors.param' },
        { key: 'События', value: 'events' }
      ],
      filterableDataLocators: [
        { key: 'Название объекта', value: 'unit.name' },
        { key: 'IMEI', value: 'unit.imei' }
      ],
      searchOption: this.lastTypeSearch,
      isSensor: false,
      isBilling: false,
      //UNITS
      filterStringUnits: '',
      checkedUnits: [],
      sortOrderUnits: '',
      sortPropUnits: '',
      //UNITSGROUPS
      filterStringUnitsgroups: '',
      checkedUnitsgroups: [],
      sortOrderUnitsgroup: '',
      sortPropUnitsgroup: '',
      //TRAILERS
      filterStringTrailers: '',
      checkedTrailers: [],
      sortOrderTrailer: '',
      sortPropTrailer: '',
      filterString: '',
      //LOCATORS
      filterStringLocators: '',
      checkedLocators: [],
      sortOrderLocator: '',
      sortPropLocator: '',
      //NEW DATA
      activeTab: 'units',
      amountPay: null,
      isBalanceTopUp: false
    }
  },

  computed: {
    ...mapGetters('login', ['role', 'disabledRole', 'notRole']),
    ...mapGetters({
      role: 'login/role',
      disabledRole: 'login/disabledRole',
      notRole: 'login/notRole',
      allUnits: 'units/allUnits',
      terminals: 'terminal/terminals'
    }),
    ...mapState({ sensortype: (state) => state.dictionary.sensor_type }),
    ...mapState('login', {
      activeCompany: (state) => state.me
    }),
    ...mapState('billing', {
      order: (state) => state.order
    }),
    ...mapState('units', {
      oldunits: (state) => state.units,
      unitsGroups: (state) => state.unitsGroups,
      lastSearchKey: (state) => state.lastSearchKey,
      lastTypeSearch: (state) => state.lastTypeSearch,
      units: (state) => state.defaultUnits,
      recoveryId: (state) => state.recoveryId
    }),
    ...mapState('trailers', {
      trailers: (state) => state.trailers,
      recoveryIdTrailer: (state) => state.recoveryId
    }),
    ...mapState('locators', {
      locators: (state) => state.locators,
      recoveryIdLocator: (state) => state.recoveryId
    }),
    arrayToDelete: {
      get() {
        if (this.activeTab === 'units') {
          return this.checkedUnits
        } else if (this.activeTab === 'unitsgroups') {
          return this.checkedUnitsgroups
        } else if (this.activeTab === 'trailers') {
          return this.checkedTrailers
        } else if (this.activeTab === 'locators') {
          return this.checkedLocators
        }
      },
      set(value) {
        if (this.activeTab === 'units') {
          this.checkedUnits = value
        } else if (this.activeTab === 'unitsgroups') {
          this.checkedUnitsgroups = value
        } else if (this.activeTab === 'trailers') {
          this.checkedTrailers = value
        } else if (this.activeTab === 'locators') {
          return (this.checkedLocators = value)
        }
      }
    },
    filterStrings: {
      get() {
        if (this.activeTab === 'unitsgroups') {
          return this.filterStringUnitsgroups
        } else if (this.activeTab === 'trailers') {
          return this.filterStringTrailers
        } else if (this.activeTab === 'locators') {
          return this.filterStringLocators
        }
      },
      set: function (value) {
        if (this.activeTab === 'unitsgroups') {
          this.filterStringUnitsgroups = value
        } else if (this.activeTab === 'trailers') {
          this.filterStringTrailers = value
        } else if (this.activeTab === 'locators') {
          this.filterStringLocators = value
        }
      }
    },
    readyOrder() {
      const arr = {}
      for (const i in this.order) {
        arr[i] =
          typeof this.order[i] === 'object'
            ? JSON.stringify(this.order[i])
            : this.order[i]
      }
      return arr
    },
    currentActiveBilling() {
      return this.activeCompany.active_company.billing.system.payment_url
    },
    tariffPrice() {
      return this.activeCompany.active_company.billing.tariff.price
    },
    filterDataUnits() {
      let units = this.allUnits
      return this.sortOrderUnits === 'ascending'
        ? this.sortPropUnits === 'name'
          ? units.sort((a, b) =>
              a.name > b.name ? 1 : b.name > a.name ? -1 : 0
            )
          : this.sortPropUnits === 'terminal_type'
          ? units.sort((a, b) =>
              a.terminal_type.value > b.terminal_type.value
                ? 1
                : b.terminal_type.value > a.terminal_type.value
                ? -1
                : 0
            )
          : units
        : this.sortOrderUnits === 'descending'
        ? this.sortPropUnits === 'name'
          ? units.sort((a, b) =>
              a.name > b.name ? -1 : b.name > a.name ? 1 : 0
            )
          : this.sortPropUnits === 'terminal_type'
          ? units.sort((a, b) =>
              a.terminal_type.value > b.terminal_type.value
                ? -1
                : b.terminal_type.value > a.terminal_type.value
                ? 1
                : 0
            )
          : units
        : units
    },
    filterDataUnitgroups() {
      let unitsGroups = this.unitsGroups.filter((unitsgroup) =>
        JSON.stringify(unitsgroup)
          .toLowerCase()
          .includes(this.filterStringUnitsgroups.toLowerCase())
      )
      return this.sortOrderUnitsgroup === 'ascending'
        ? this.sortPropUnitsgroup === 'name'
          ? unitsGroups.sort((a, b) =>
              a.name > b.name ? 1 : b.name > a.name ? -1 : 0
            )
          : this.sortPropUnitsgroup === 'units'
          ? unitsGroups.sort((a, b) => b.units.length - a.units.length)
          : this.sortPropUnitsgroup === 'users'
          ? unitsGroups.sort((a, b) => b.users.length - a.users.length)
          : unitsGroups
        : this.sortOrderUnitsgroup === 'descending'
        ? this.sortPropUnitsgroup === 'name'
          ? unitsGroups.sort((a, b) =>
              a.name > b.name ? -1 : b.name > a.name ? 1 : 0
            )
          : this.sortPropUnitsgroup === 'units'
          ? unitsGroups.sort((a, b) => a.units.length - b.units.length)
          : this.sortPropUnitsgroup === 'users'
          ? unitsGroups.sort((a, b) => a.users.length - b.users.length)
          : unitsGroups
        : unitsGroups
    },
    filterDataTrailers() {
      let trailers = this.trailers.filter((trailer) =>
        JSON.stringify(trailer)
          .toLowerCase()
          .includes(this.filterStringTrailers.toLowerCase())
      )
      return this.sortOrderTrailer === 'ascending'
        ? this.sortPropTrailer === 'name'
          ? trailers.sort((a, b) =>
              a.name > b.name ? 1 : b.name > a.name ? -1 : 0
            )
          : this.sortPropTrailer === 'code'
          ? trailers.sort((a, b) =>
              a.code > b.code ? 1 : b.code > a.code ? -1 : 0
            )
          : trailers
        : this.sortOrderTrailer === 'descending'
        ? this.sortPropTrailer === 'name'
          ? trailers.sort((a, b) =>
              a.name > b.name ? -1 : b.name > a.name ? 1 : 0
            )
          : this.sortPropTrailer === 'units'
          ? trailers.sort((a, b) =>
              a.code > b.code ? -1 : b.code > a.code ? 1 : 0
            )
          : trailers
        : trailers
    },
    filterDataLocators() {
      let locators = this.locators.filter((locator) =>
        JSON.stringify(locator)
          .toLowerCase()
          .includes(this.filterStringLocators.toLowerCase())
      )

      return this.sortOrderLocator === 'ascending'
        ? this.sortPropLocator === 'name'
          ? locators.sort((a, b) =>
              a.unit.name > b.unit.name ? 1 : b.unit.name > a.unit.name ? -1 : 0
            )
          : this.sortPropLocator === 'active'
          ? locators.sort((a, b) =>
              Number(a.is_active) > Number(b.is_active)
                ? 1
                : Number(b.is_active) > Number(a.is_active)
                ? -1
                : 0
            )
          : locators
        : this.sortOrderLocator === 'descending'
        ? this.sortPropLocator === 'name'
          ? locators.sort((a, b) =>
              a.unit.name > b.unit.name ? -1 : b.unit.name > a.unit.name ? 1 : 0
            )
          : this.sortPropLocator === 'active'
          ? locators.sort((a, b) =>
              Number(a.is_active) > Number(b.is_active)
                ? -1
                : b.unit.imei > Number(a.is_active)
                ? 1
                : 0
            )
          : locators
        : locators
    },
    isIndeterminateUnits() {
      return (
        this.checkedUnits.length > 0 &&
        this.checkedUnits.length < this.filterDataUnits.length
      )
    },
    isIndeterminateUnitsgroups() {
      return (
        this.checkedUnitsgroups.length > 0 &&
        this.checkedUnitsgroups.length < this.filterDataUnitgroups.length
      )
    },
    isIndeterminateTrailer() {
      return (
        this.checkedTrailers.length > 0 &&
        this.checkedTrailers.length < this.filterDataTrailers.length
      )
    },
    isIndeterminateLocator() {
      return (
        this.checkedLocators.length > 0 &&
        this.checkedLocators.length < this.filterDataLocators.length
      )
    },
    unitIds() {
      return this.filterDataUnits.map((k) => k.id)
    },
    unitsgroupIds() {
      return this.filterDataUnitgroups.map((k) => k.id)
    },
    trailersIds() {
      return this.filterDataTrailers.map((k) => k.id)
    },
    locatorsIds() {
      return this.filterDataLocators.map((l) => l.id)
    },
    isAllCheckedUnits: {
      get() {
        return (
          this.checkedUnits.length >= this.filterDataUnits.length &&
          this.filterDataUnits.length
        )
      },
      set(value) {
        this.checkedUnits = value ? this.unitIds : []
      }
    },
    isAllCheckedUnitsgroups: {
      get() {
        return (
          this.checkedUnitsgroups.length >= this.filterDataUnitgroups.length &&
          this.filterDataUnitgroups.length
        )
      },
      set(value) {
        this.checkedUnitsgroups = value ? this.unitsgroupIds : []
      }
    },
    isAllCheckedTrailers: {
      get() {
        return (
          this.checkedTrailers.length >= this.filterDataTrailers.length &&
          this.filterDataTrailers.length
        )
      },
      set(value) {
        this.checkedTrailers = value ? this.trailersIds : []
      }
    },
    isAllCheckedLocators: {
      get() {
        return (
          this.checkedLocators.length >= this.filterDataLocators.length &&
          this.filterDataLocators.length
        )
      },
      set(value) {
        this.checkedLocators = value ? this.locatorsIds : []
      }
    },
    locatorsSelectedLength() {
      return this.checkedLocators.length
    },
    // activeTabTitle() {
    // 	console.log(this.activeTab, 'this.activeTab');
    // 	switch (this.activeTab) {
    // 		case 'units':
    // 			return this.$t('objects.title')
    // 		case 'unitsgroups':
    // 			return this.$t('objects.titlegroups')
    // 		case 'trailers':
    // 			return this.$t('objects.titleTrailers')
    // 		case 'locators':
    // 			return this.$t('objects.titleLocators')
    // 		default:
    // 			return this.$t('objects.title')
    // 	}
    // },

    disabledRoleLocators() {
      const notAllowedRoles = ['READER', 'EDITOR', 'OBSERVER', 'OPERATOR']

      return notAllowedRoles.includes(this.role)
    }
  },
  watch: {
    order(val) {
      this.isPayementReady = true
    },
    lastSearchKey: function (val) {
      this.filterString = val
    },
    '$parent.spinnerLoading': function (val) {
      this.reset()
    },
    activeTab: function (val) {
      if (val !== 'units') {
        this.isSensor = false
        this.$store.dispatch('units/HANDLE_KEYSEARCH', '')
        this.$store.dispatch('units/HANDLE_TYPESEARCH', '')
        this.checkedUnits = []
        this.checkedUnitsgroups = []
        this.checkedTrailers = []
        this.checkedLocators = []
      } else {
        this.filterString = this.lastSearchKey
      }
    }
  },
  methods: {
    ...mapActions({
      createNewOrder: 'billing/CREATE_NEWORDER',
      createOrder: 'billing/CREATE_ORDER',
      getTerminals: 'terminal/getTerminals'
    }),
    ...mapMutations({
      setBillingInfo: 'login/setBillingInfo'
    }),
    // onTabChange (name) {
    // 	console.log(name, 'sss');
    // 	this.activeTab = name
    // },
    searching(val) {
      this.filterStrings = val
    },
    locatorStatus(locator) {
      if (locator.is_active) {
        return 'Активна'
      }

      return 'Не активна'
    },
    closeDeletedNotification() {
      setTimeout(() => {
        this.$notify.closeAll()
      }, 10000)
    },
    showHistoryChanges() {
      if (this.notRole) {
        const objects = { objects: this.activeTab }
        this.$emit('show-history', objects)
      }
    },
    unblockCompany(amount) {
      const formData = {
        amount: amount.amount,
        success_url: currentUrl,
        skip_init_payment: true
      }

      const formDataNewTariff = {
        tariff: {
          units: amount.amount / this.tariffPrice,
          length: 30,
          price: this.tariffPrice
        },
        success_url: currentUrl
      }

      this.createNewOrder(formDataNewTariff)
        .then((response) => {
          if (response.status === 200) {
            this.setBillingInfo(response.data)
            return this.createOrder(formData)
          }
          throw new Error('Error')
        })
        .then((response) => {
          if (
            this.activeCompany.active_company.billing.tariff.type ===
              'charge_sent_units' ||
            this.activeCompany.active_company.billing.company_status === 'TRIAL'
          )
            return
          if (response.status === 200) {
            const currentAmount =
              this.activeCompany.active_company.billing.amount
            const diffrenceAmount = amount.amount - currentAmount
            if (diffrenceAmount > 0) {
              /** ~AV отобразить frame оплаты */
              // this.isBalanceTopUp = true;
            }
          } else {
            this.$notify.error({
              duration: 5000,
              title: `${this.$t('error')} \u{1f62a}`,
              message: response
            })
          }
        })
        .catch((error) => {
          this.$notify.error({
            duration: 5000,
            title: `${this.$t('error')} \u{1f62a}`,
            message: error
          })
          return 'Error'
        })
    },
    changeActivity(id) {
      const locator = this.filterDataLocators.find((l) => l.id === id)

      console.log('locator', locator)

      if (locator) {
        this.$store.dispatch('locators/UPDATE', locator)
      }
    },
    sensorChange() {
      this.reset()
    },
    handlLastTypeSearch(val) {
      this.searchOption = val
      if (val === 'sensors.type.key') {
        this.$store.commit('units/SET_OLDUNITS')
      }
    },
    handlEdit: _.debounce(
      function (val) {
        console.log('edit', 222)
        this.$emit('update-unit', { unitId: val.id, fromModule: 'units' })
        this.$store.dispatch('units/HANDLE_TYPESEARCH', this.searchOption)
      },
      200,
      {
        leading: true,
        trailing: false
      }
    ),
    handlSensor(val) {
      let formData = {
        conditions: [
          {
            field: 'sensors.type.key',
            value: val
          }
        ],
        fields: ['id']
      }
      this.$store.dispatch('units/SEARCH_UNITSENSOR', { formData })
    },
    handlClose() {
      console.log('handle close')
      this.$emit('close')
      this.$store.dispatch('units/HANDLE_KEYSEARCH', '')
      this.$store.dispatch('units/HANDLE_TYPESEARCH', '')
      this.$store.dispatch('units/SEARCH_UNIT', '')
      this.$store.commit('units/RESET_SEARCH_UNITS')
    },
    handleSearchClick() {
      let value

      switch (this.activeTab) {
        case 'units':
          value = this.filterStringUnits
          break
        case 'unitsgroups':
          value = this.filterStringUnitsgroups
          break
        case 'trailers':
          value = this.filterStringTrailers
          break
        case 'locators':
          value = this.filterStringLocators
          break
      }

      let formData = {
        conditions: [
          {
            field: this.searchOption,
            value: this.filterString
          }
        ],
        fields: ['id']
      }

      if (this.activeTab === 'units') {
        if (this.filterString.length > 0) {
          this.$store.dispatch('units/SEARCH_UNIT', { formData })
          this.$store.dispatch('units/HANDLE_KEYSEARCH', this.filterString)
          this.checkedUnits = []
          this.checkedUnitsgroups = []
          this.checkedTrailers = []
          this.checkedLocators = []
        } else {
          this.$store.commit('units/SET_OLDUNITS')
        }
      } else if (this.activeTab === 'unitsgroups') {
        this.filterStringUnitsgroups = value
      } else if (this.activeTab === 'trailers') {
        this.filterStringTrailers = value
      } else {
        this.filterStringLocators = value
      }
    },
    handleFilteredSearch(val) {
      this.searchOption = val
      if (val === 'sensors.type.key') {
        this.isSensor = true
        this.checkedUnits = []
        this.checkedUnitsgroups = []
        this.checkedTrailers = []
        this.checkedLocators = []
      } else {
        this.isSensor = false
      }
    },
    sortChangeTrailers(prop) {
      if (this.sortPropTrailer === prop) {
        if (this.sortOrderTrailer === 'ascending') {
          this.sortOrderTrailer = 'descending'
        } else if (this.sortOrderTrailer === 'descending') {
          this.sortOrderTrailer = ''
          this.sortPropTrailer = ''
        } else {
          this.sortOrderTrailer = 'ascending'
        }
      } else {
        this.sortPropTrailer = prop
        this.sortOrderTrailer = 'ascending'
      }
    },
    sortChangeLocators(prop) {
      if (this.sortPropLocator === prop) {
        if (this.sortOrderLocator === 'ascending') {
          this.sortOrderLocator = 'descending'
        } else if (this.sortOrderLocator === 'descending') {
          this.sortOrderLocator = ''
          this.sortPropLocator = ''
        } else {
          this.sortOrderLocator = 'ascending'
        }
      } else {
        this.sortPropLocator = prop
        this.sortOrderLocator = 'ascending'
      }
    },
    sortChangeUnitsgroups(prop) {
      if (this.sortPropUnitsgroup === prop) {
        if (this.sortOrderUnitsgroup === 'ascending') {
          this.sortOrderUnitsgroup = 'descending'
        } else if (this.sortOrderUnitsgroup === 'descending') {
          this.sortOrderUnitsgroup = ''
          this.sortPropUnitsgroup = ''
        } else {
          this.sortOrderUnitsgroup = 'ascending'
        }
      } else {
        this.sortPropUnitsgroup = prop
        this.sortOrderUnitsgroup = 'ascending'
      }
    },
    sortChangeUnits(prop) {
      if (this.sortPropUnits === prop) {
        if (this.sortOrderUnits === 'ascending') {
          this.sortOrderUnits = 'descending'
        } else if (this.sortOrderUnits === 'descending') {
          this.sortOrderUnits = ''
          this.sortPropUnits = ''
        } else {
          this.sortOrderUnits = 'ascending'
        }
      } else {
        this.sortPropUnits = prop
        this.sortOrderUnits = 'ascending'
      }
    },
    closeLocatorPopover(index) {
      this.$refs['locator-actions-popover'][index].showPopper = false
    },
    copyLinkToClipboard(locatorId, index) {
      const link = `https://${window.location.host}/locator?id=${locatorId}`
      navigator.clipboard.writeText(link)

      this.closeLocatorPopover(index)

      this.$notify.success({
        message: this.$t('objects.locator.copy-link'),
        position: 'top-left'
      })
    },
    updateLocatorEmit(locatorID, index) {
      this.closeLocatorPopover(index)

      this.$emit('update-locator', locatorID)
    },
    addUnit() {
      const currentTariff = this.activeCompany.active_company.billing.tariff
      if (this.activeTab === 'units') {
        if (
          currentTariff.price > 0 &&
          (currentTariff.units === 0 ||
            this.units.length >= currentTariff.units)
        ) {
          this.isBilling = true
        } else if (currentTariff.units === 0 && currentTariff.price === 0) {
          console.log('company old but don;t pay')
          if (this.activeTab === 'units') {
            this.$emit('create-unit')
          } else if (this.activeTab === 'unitsgroups') {
            this.$emit('create-unitsgroup')
          } else if (this.activeTab === 'trailers') {
            this.$emit('create-trailer')
          } else {
            this.$emit('create-locator')
          }
        } else {
          if (this.activeTab === 'units') {
            this.$emit('create-unit')
          } else if (this.activeTab === 'unitsgroups') {
            this.$emit('create-unitsgroup')
          } else if (this.activeTab === 'trailers') {
            this.$emit('create-trailer')
          } else {
            this.$emit('create-locator')
          }
        }
      } else if (this.activeTab === 'unitsgroups') {
        this.$emit('create-unitsgroup')
      } else if (this.activeTab === 'trailers') {
        this.$emit('create-trailer')
      } else {
        this.$emit('create-locator')
      }
    },
    cancel() {
      this.arrayToDelete = []
    },
    recoverItems(items, type) {
      let recoveryId = ''
      if (type === 'trailers') {
        recoveryId = this.recoveryIdTrailer
      } else if (type === 'locators') {
        recoveryId = this.recoveryIdLocator
      } else {
        recoveryId = this.recoveryId.id
      }

      let recoveryObject = {
        id: recoveryId,
        itemsDeleted: items,
        type: type
      }

      if (type === 'trailers') {
        this.$store.dispatch('trailers/RECOVER_TRAILERS', recoveryObject)
      } else if (type === 'locators') {
        this.$store.dispatch('locators/RECOVER_LOCATORS', recoveryObject)
      } else {
        this.$store.dispatch('units/RECOVER_ITEMS', recoveryObject)
      }

      this.$notify.closeAll()
    },

    deleteLocators() {
      const locatorsToDelete = this.filterDataLocators.filter((el) =>
        this.checkedLocators.some((id) => el.id === id)
      )

      const locatorsToDeleteIds = locatorsToDelete.map((l) => l.id)

      this.$store.dispatch('locators/DELETE', locatorsToDeleteIds).then((_) => {
        this.cancel()
      })

      this.$notify({
        dangerouslyUseHTMLString: true,
        message: `<div >
                      <span class="" > ${
                        locatorsToDeleteIds.length <= 1
                          ? this.$t('one_element_deleted')
                          : locatorsToDeleteIds.length +
                            this.$t('already_deleted_elements')
                      } </span>
                    </div>`,
        duration: 3000,
        position: 'bottom-left',
        customClass: 'confirmation'
      })
    },
    deleteUnits() {
      let currentElements = this.arrayToDelete

      let someUnits = this.filterDataUnits.filter((el) =>
        this.arrayToDelete.some((id) => el.id === id)
      )
      let someUnitsGroups = this.filterDataUnitgroups.filter((el) =>
        this.arrayToDelete.some((id) => el.id === id)
      )
      let someUnitsTrailers = this.filterDataTrailers.filter((el) =>
        this.arrayToDelete.some((id) => el.id === id)
      )
      let someUnitsLocators = this.filterDataLocators.filter((el) => {
        return this.arrayToDelete.some((id) => el.id === id)
      })

      this.$notify({
        dangerouslyUseHTMLString: true,
        message: `<div >
                      <span class="" > ${
                        currentElements.length <= 1
                          ? this.$t('one_element_deleted')
                          : currentElements.length +
                            this.$t('already_deleted_elements')
                      } </span>
                      <span id='confirm' class='cursor-pointer cancelConfirm text-annotationColor'>${this.$t(
                        'cancel'
                      )}</span>
                    </div>`,
        duration: 10000,
        position: 'bottom-left',
        customClass: 'confirmation'
      })

      this.closeDeletedNotification()
      let self = this
      let confirmation = document.getElementById('confirm')
      if (this.activeTab === 'units') {
        this.$store
          .dispatch('units/DELETE_UNIT', this.arrayToDelete)
          .then((_) => {
            this.cancel()
          })
        confirmation.addEventListener(
          'click',
          function () {
            self.recoverItems(someUnits, 'units')
          },
          false
        )
      } else if (this.activeTab === 'unitsgroups') {
        this.$store
          .dispatch('units/DELETE_GROUPUNIT', this.arrayToDelete)
          .then((_) => {
            this.cancel()
          })
        confirmation.addEventListener(
          'click',
          function () {
            self.recoverItems(someUnitsGroups, 'unitsgroups')
          },
          false
        )
      } else if (this.activeTab === 'trailers') {
        this.$store
          .dispatch('trailers/DELETE', this.arrayToDelete)
          .then((_) => {
            this.cancel()
          })
        confirmation.addEventListener(
          'click',
          function () {
            self.recoverItems(someUnitsTrailers, 'trailers')
          },
          false
        )
      } else if (this.activeTab === 'locators') {
        this.$store.dispatch('locators/DELETE', this.arrayToDelete).then(() => {
          this.cancel()
        })
        confirmation.addEventListener(
          'click',
          function () {
            self.recoverItems(someUnitsLocators, 'locators')
          },
          false
        )
      }
    },
    reset() {
      this.filterStringUnits = ''
      this.filterStringUnitsgroups = ''
      this.filterStringTrailers = ''
      this.filterStringLocators = ''
      this.checkedUnits = []
      this.checkedUnitsgroups = []
      this.checkedTrailers = []
      this.checkedLocators = []
    }
  },
  beforeMount() {
    this.$store.commit('units/RESET_SEARCH_UNITS')
    this.getTerminals({})
  },

  beforeDestroy() {
    this.activeTab = ''
  },
  async created() {
    this.filterString = this.lastSearchKey
    this.searchOption = this.lastTypeSearch
  },

  mounted() {
    if (this.activeTabUnits === 'locators') {
      this.activeTab = 'locators'
    } else if (this.activeTabUnits === 'trailers') {
      this.activeTab = 'trailers'
    } else if (this.activeTabUnits === 'unitsgroups') {
      this.activeTab = 'unitsgroups'
    }
  }
}
</script>
<script setup>
import {ref, watch, getCurrentInstance, onUnmounted, onMounted, computed} from 'vue'

import { useI18n } from '@/hooks/useI18n'
import { objectsApi, locatorsApi } from '@/api'

import { createVuexHelpers } from 'vue2-helpers'

import UnitsShowPage from './components/UnitsShowPage.vue'
import UnitsOverlay from './components/UnitsOverlay.vue'
import UnitsSaveModal from './components/UnitsSaveModal.vue'
import ObjectsWrapper from './components/Objects/ObjectsWrapper.vue'
import GroupsShowPage from './components/GroupsShowPage.vue'
import LinksForm from './components/LinksForm.vue'
import BillingForm from '../units/subComponents/billingForm.vue'
import sections from '@/enums/newUnits/main-sections.js'
import {useStore} from "@/store/store.js";

const { useGetters, useMutations, useActions } = createVuexHelpers()

const instance = getCurrentInstance()
const $t = useI18n()

const props = defineProps({
  objectId: {
    type: String,
    default: ''
  }
})

const {
  SET_ACTIVE_OBJECT,
  SET_MODAL_TYPE,
  SET_ERRORS,
  SET_NEW_COUPLINGS_ACTIVE_OBJECT,
  SET_NEW_SHIFTS_ACTIVE_OBJECT,
  SET_FIELDS_ACTIVE_OBJECT,
  SET_OBJECTS_MODAL,
  SET_FROM_MONITORING
} = useMutations('units', [
  'SET_ACTIVE_OBJECT',
  'SET_MODAL_TYPE',
  'SET_ERRORS',
  'SET_NEW_COUPLINGS_ACTIVE_OBJECT',
  'SET_NEW_SHIFTS_ACTIVE_OBJECT',
  'SET_FIELDS_ACTIVE_OBJECT',
  'SET_OBJECTS_MODAL',
  'SET_FROM_MONITORING'
])

const { GET_UNITS, DELETE_GROUPUNIT, DELETE_UNIT } = useActions('units', [
  'GET_UNITS',
  'DELETE_GROUPUNIT',
  'DELETE_UNIT'
])

const { notRole, activeCompany } = useGetters('login', [
  'notRole',
  'activeCompany'
])
const { unitGroup, units, objectsWrapperModal, fromMonitoring } = useGetters(
  'units',
  ['unitGroup', 'units', 'objectsWrapperModal', 'fromMonitoring']
)

const { SET_LOCATOR } = useMutations('locators', ['SET_LOCATOR'])
const { CREATE, UPDATE, DELETE } = useActions('locators', [
  'CREATE',
  'UPDATE',
  'DELETE'
])

const emits = defineEmits([
  'close',
  'show-history',
  'onClearEditedObjectId',
  'onBackToMonitoring'
])

const modal = ref({
  open: false,
  type: 'create'
})

const saveModal = ref(false)
const isOverlay = ref(false)
const activeObject = ref(null)
const isBilling = ref(false)
const isGroups = ref({
  open: false,
  type: 'create',
  activeGroup: {}
})
const isLinks = ref({
  open: false,
  type: 'create'
})

const loading = ref(false)
const linksLoading = ref(false)

const openGroupModal = (type) => {
  isGroups.value.open = true
  isGroups.value.type = type
}

const closeGroupModal = (type) => {
  isGroups.value.open = false
  isGroups.value.type = 'create'

  if (type) instance.proxy.$showSuccess($t(`new_units.groups.success.${type}`))
}

const openLinksEditModal = async (type, id) => {

  if (id) {
    locatorsApi.getLocator(
      id,
      (response) => {
        const { data } = response

        SET_LOCATOR(data)

        linksModalTrigger(true, type)
      },
      (error) => {
        eventBus.$showError(error.response.data.message)
      }
    )
  }
}

const openModal = async (type, { id, tab }) => {
  const currentTariff = activeCompany.value.billing.tariff
  const allUnits = units.value
  if (type === 'create') {
    if (
      currentTariff.price > 0 &&
      (currentTariff.units === 0 || allUnits.length >= currentTariff.units)
    ) {
      isBilling.value = true
    } else {
      SET_MODAL_TYPE('create')

      modal.value.type = 'create'
      modal.value.open = true
    }
  } else {
    if (tab === sections.OBJECTS) {
      openObjectEditModal(id)
    }

    if (tab === sections.GROUPS) {
      isGroups.value.activeGroup = unitGroup.value(id)
      openGroupModal('edit')
    }

    if (tab === sections.ACCESS_LINKS) {
      openLinksEditModal('edit', id)
    }
  }
}

const openObjectEditModal = (id) => {
  // Store.commit('accessSettings/setIsAccessSettings', true)
  objectsApi.getObject(
    id,
    (response) => {
      activeObject.value = JSON.parse(JSON.stringify(response.data))

      SET_ACTIVE_OBJECT(activeObject.value)
      SET_MODAL_TYPE('edit')

      modal.value.type = 'edit'
      modal.value.open = true
    },
    (error) => {
      instance.proxy.$showError(error.response.data.message)
    }
  )
}

const handleContinue = () => {
  saveModal.value = false
  isOverlay.value = false

  SET_MODAL_TYPE('edit')
  modal.value.type = 'edit'
}

const handleSaveModal = (value = false) => {
  saveModal.value = value
  isOverlay.value = value

  if (!value) clearData()
}

const handleDelete = ({ tab, ids }) => {
  switch (tab) {
    case sections.OBJECTS:
      deleteObjects(ids)
      break
    case sections.GROUPS:
      deleteGroups(ids)
      break
    case sections.ACCESS_LINKS:
      deleteLinks(ids)
      break
  }
}

const deleteLinks = async (ids) => {
  try {
    loading.value = true

    await DELETE(ids)

    instance.proxy.$showSuccess($t('new_units.links.success.delete'))
  } catch (err) {
    console.error(err)
    instance.proxy.$showError($t('new_units.links.errors.delete'))
  } finally {
    loading.value = false
  }
}

const deleteGroups = async (ids) => {
  try {
    loading.value = true

    await DELETE_GROUPUNIT(ids)

    instance.proxy.$showSuccess($t('new_units.groups.success.delete'))
  } catch (err) {
    console.log(err, 'catch')
    instance.proxy.$showError($t('new_units.groups.errors.delete'))
  } finally {
    loading.value = false
  }
}

const deleteObjects = async (ids) => {
  try {
    loading.value = true
    await DELETE_UNIT(ids)

    instance.proxy.$showSuccess($t('new_units.objects.success.delete'))
  } catch (err) {
    instance.proxy.$showError($t('new_units.objects.errors.delete'))
  } finally {
    loading.value = false
  }
}

const handleShowHistory = ({ objects, objectId }) => {
  const data = { objects, objectId }

  if (objectId) {
    emits('show-history', data)

    return
  }

  if (notRole.value) {
    emits('show-history', { objects })
  }
}

const clearData = () => {
  modal.value.open = false

  SET_ACTIVE_OBJECT(null)
  SET_ERRORS([])
  SET_MODAL_TYPE('')
  SET_NEW_COUPLINGS_ACTIVE_OBJECT([])
  SET_NEW_SHIFTS_ACTIVE_OBJECT([])
  SET_FIELDS_ACTIVE_OBJECT([])

  if (fromMonitoring.value) {
    emits('onBackToMonitoring')
    SET_FROM_MONITORING(false)
  }
}

const linksModalTrigger = (open = false, type = 'create') => {
  isLinks.value.open = open
  isLinks.value.type = type
}

const submitLink = async (type, link) => {
  try {
    isLinks.value.open ? (linksLoading.value = true) : (loading.value = true)

    type === 'edit' ? await UPDATE(link) : await CREATE(link)

    instance.proxy.$showSuccess($t(`new_units.links.success.${type}`))

    linksModalTrigger()
  } catch (err) {
    instance.proxy.$showError(
      $t(`new_units.links.errors.${err.response.message}`)
    )
  } finally {
    linksLoading.value = false
    loading.value = false
  }
}

watch(
  () => props.objectId,
  (val) => {
    if (val) {
      SET_FROM_MONITORING(true)
      openModal('edit', { id: val, tab: sections.OBJECTS })

      emits('onClearEditedObjectId')
    }
  },
  { immediate: true }
)

const handleClose = () => {
  emits('close')
}

const Store = useStore()

watch(
    ()=>Store.getters['accessSettings/getIsAccessSettings'],
    ()=>{
      if(Store.getters['accessSettings/getIsAccessSettings']){
        emits('close')
      }
    }
)

const handleBack = () => {
  clearData()
}

onMounted(() => {
  modal.value = objectsWrapperModal.value
})

onUnmounted(() => {
  SET_OBJECTS_MODAL(modal.value)

  emits('onClearEditedObjectId')
})
</script>
