<template>
  <div v-loading="loading" class="h-full">
    <UnitsHeader
      :active-tab="activeTab"
      :is-modify="isModify"
      @onClose="handleClose"
      @onCreate="handleCreate"
      @onCreateGroup="handleCreateGroup"
      @onCreateLink="handleCreateLink"
      @onSearch="handleSearch"
      @onSensorTypeSearch="handleSearch"
      @onShowHistory="handleShowHistory"
    />
    <div class="flex justify-between items-center relative divider z-10 px-5">
      <UnitsMainTabs :active-name="activeTab" @onChange="onTabChange" />
      <div class="flex gap-2 items-center">
        <AddRectangle
          v-if="activeTab === sections.OBJECTS"
          class="mr-3 cursor-pointer"
          :class="{ disabled: disabled }"
          @click.native="showModalSensors = true"
        />
        <!--        <div class="developing">-->
        <!--          <div class="developing__info">В разработке</div>-->
        <!--          <AddRectangle color="red" class="developing__icon disabled"/>-->
        <!--        </div>-->
        <UnitsDeleteButton
          v-if="isShowDeleteButton"
          :deleted-count="deletedItems.length"
          @onDelete="handleDelete"
        />
      </div>
    </div>
    <UnitsTable
      v-if="!isTrailers"
      :table-data="tableData.list"
      :titles="tableData.titles"
      :options="tableData.options"
      :empty="emptyData"
      :key="tableRenderKey"
      :selected-items="selectedData"
      :load-more="loadMore"
      table-id="table-id"
      class="main-table"
      ref="listTable"
      @onEdit="handleEdit"
      @onSelect="selectRow"
      @onSelectAll="selectAll"
      @onRowClick="handleEdit"
      @onSort="handleSort"
      @onLoadData="loadData"
      @onChangeLinkStatus="changeLinkStatus"
    />
    <UnitsTrailers v-if="isTrailers" />
    <ModalSensors
      v-if="showModalSensors"
      v-model="showModalSensors"
      @onSubmit="copySensors"
    />
  </div>
</template>

<script setup>
import { computed, ref, onMounted, watch, onBeforeUnmount } from 'vue'

import { useI18n } from '@/hooks/useI18n'
import { createVuexHelpers } from 'vue2-helpers'
import {
  searchHelper,
  sortHelper
} from '@/components/unitsNew/helpers/index.js'

import UnitsHeader from '@/components/unitsNew/components/UnitsHeader.vue'
import UnitsMainTabs from '@/components/unitsNew/components/UnitsMainTabs.vue'
import UnitsDeleteButton from '@/components/unitsNew/components/UnitsDeleteButton.vue'
import UnitsTable from '@/components/unitsNew/components/UnitsTable.vue'
import UnitsTrailers from '@/components/unitsNew/components/UnitsTrailers.vue'
import AddRectangle from '@/components/unitsNew/assets/icons/add-rectangle.vue'
import ModalSensors from '@/components/unitsNew/components/modalSensors/modalSensors.vue'

import sections from '@/enums/newUnits/main-sections.js'
import linksStatus from '@/enums/newUnits/links-status.js'
import eventBus from '@/eventBus.js'
import { objectsApi } from '@/api'
import moment from 'moment'

const { useGetters, useMutations, useActions } = createVuexHelpers()

const {
  units,
  unitsGroup,
  searchedUnits,
  searchedGroups,
  activeElementScroll,
  currentTableSortType
} = useGetters('units', [
  'units',
  'unitsGroup',
  'searchedUnits',
  'searchedGroups',
  'activeElementScroll',
  'currentTableSortType'
])
const { allTrailers } = useGetters('trailers', ['allTrailers'])
const { SET_TRAILERS_SEARCHED, SET_SCROLL_TOP } = useMutations('trailers', [
  'SET_TRAILERS_SEARCHED',
  'SET_SCROLL_TOP'
])

const { locators, searchedLinks, immutableLinks } = useGetters('locators', [
  'locators',
  'searchedLinks',
  'immutableLinks'
])

const { formattedCurrentTime } = useGetters('login', ['formattedCurrentTime'])

const { SET_LINKS_SEARCHED } = useMutations('locators', ['SET_LINKS_SEARCHED'])

const {
  RESET_SEARCH_UNITS,
  SET_GROUPS_SEARCHED,
  SORT_UNITS,
  SORT_GROUPS,
  SET_ACTIVE_ELEMENT_SCROLL,
  SET_CURRENT_TABLE_SORT_TYPE
} = useMutations('units', [
  'RESET_SEARCH_UNITS',
  'SORT_UNITS',
  'SORT_GROUPS',
  'SET_GROUPS_SEARCHED',
  'SET_ACTIVE_ELEMENT_SCROLL',
  'SET_CURRENT_TABLE_SORT_TYPE'
])

const {
  SEARCH_UNIT,
  HANDLE_KEYSEARCH,
  HANDLE_TYPESEARCH,
  GET_UNITS,
  GET_GROUP_UNITS
} = useActions('units', [
  'SEARCH_UNIT',
  'HANDLE_KEYSEARCH',
  'HANDLE_TYPESEARCH',
  'GET_UNITS',
  'GET_GROUP_UNITS'
])

const $t = useI18n()

const arr = ref([])

const props = defineProps({
  isModify: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(
  'onClose',
  'onCreate',
  'onEdit',
  'onBack',
  'onDelete',
  'onShowHistory'
)

const LIST_COUNT = 50
const showModalSensors = ref(false)
const activeTab = ref(sections.OBJECTS)
const defaultListCount = ref(LIST_COUNT)
const listTable = ref(null)
const loadMore = ref(false)
const tableRenderKey = ref(0)
const unitsCount = computed(() => units.value.length)
const emptyData = computed(() => {
  return {
    icon: activeTab.value,
    text: $t(`new_units.${activeTab.value}.no_${activeTab.value}`)
  }
})

const deletedItems = ref([])
const selectedData = ref([])

const isShowDeleteButton = computed(() => {
  return (
    activeTab.value === sections.OBJECTS ||
    activeTab.value === sections.GROUPS ||
    activeTab.value === sections.ACCESS_LINKS
  )
})

const isTrailers = computed(() => {
  return activeTab.value === sections.TRAILERS
})

const tableData = computed(() => {
  switch (activeTab.value) {
    case sections.OBJECTS:
      return objectsTable.value
    case sections.GROUPS:
      return groupsTable.value
    case sections.ACCESS_LINKS:
      return linksTable.value
    default:
      return objectsTable.value
  }
})

const objectsTable = ref({
  list: [],
  titles: [
    {
      label: $t('new_units.objects.table_columns.name'),
      prop: 'name',
      width: '10',
      headerAlign: 'left',
      align: 'left',
      icon: true,
      orderSort: '',
      class: ['max-w-textLong']
    },
    {
      label: $t('new_units.objects.table_columns.protocol'),
      prop: 'protocol',
      width: '8',
      headerAlign: 'center',
      align: 'center',
      icon: true,
      orderSort: '',
      class: ['greyToneThird']
    },
    {
      label: $t('new_units.objects.table_columns.imei'),
      prop: 'imei',
      width: '8',
      headerAlign: 'center',
      align: 'center',
      class: ['greyToneThird', 'max-w-textMiddle']
    },
    {
      label: $t('new_units.objects.table_columns.sim_1_2'),
      prop: 'sim_1_2',
      width: '6',
      headerAlign: 'center',
      align: 'center',
      class: ['greyToneThird', 'max-w-textMiddle']
    },
    {
      label: $t('new_units.objects.table_columns.sensors'),
      prop: 'sensors_count',
      width: '8',
      headerAlign: 'right',
      align: 'center',
      sortType: 'number',
      icon: true,
      orderSort: '',
      class: ['greyToneThird']
    },
    {
      label: '',
      prop: 'settings',
      width: '5',
      headerAlign: 'right',
      align: 'left'
    }
  ],
  options: {
    hasCheckboxColumn: true,
    recovered: []
  },
  scrollTop: 0,
  scrollCount: LIST_COUNT
})

const groupsTable = ref({
  list: [],
  titles: [
    {
      label: $t('new_units.groups.table_columns.group_name'),
      prop: 'name',
      width: '30',
      headerAlign: 'left',
      align: 'left',
      icon: true,
      orderSort: '',
      class: ['max-w-30']
    },
    {
      label: $t('new_units.groups.table_columns.users_count'),
      prop: 'userCount',
      width: '30',
      icon: true,
      sortType: 'number',
      orderSort: '',
      class: ['max-w-30', 'greyToneThird'],
      headerAlign: 'center',
      align: 'center'
    },
    {
      label: $t('new_units.groups.table_columns.objects'),
      prop: 'objects',
      width: '10',
      headerAlign: 'center',
      align: 'center',
      sortType: 'number',
      orderSort: '',
      icon: true,
      class: ['greyToneThird']
    },
    {
      label: '',
      prop: 'settings',
      width: '10',
      headerAlign: 'right',
      align: 'center'
    }
  ],
  options: {
    hasCheckboxColumn: true,
    recovered: []
  },
  scrollTop: 0,
  // scrollCount: LIST_COUNT
})

const linksTable = ref({
  list: [],
  titles: [
    {
      label: $t('new_units.links.table_columns.object'),
      prop: 'object',
      width: '25',
      headerAlign: 'left',
      align: 'left',
      orderSort: '',
      icon: true,
      class: ['max-w-textLong']
    },
    {
      label: $t('new_units.links.table_columns.active_from'),
      prop: 'date_from',
      fullTime: true,
      width: '16',
      headerAlign: 'center',
      align: 'center',
      orderSort: '',
      sortType: 'date',
      icon: true,
      class: []
    },
    {
      label: $t('new_units.links.table_columns.active_to'),
      prop: 'date_to',
      fullTime: true,
      width: '16',
      headerAlign: 'center',
      align: 'center',
      orderSort: '',
      sortType: 'date',
      icon: true,
      class: []
    },
    {
      label: $t('new_units.links.table_columns.status'),
      prop: 'statusText',
      width: '15',
      headerAlign: 'center',
      align: 'center',
      orderSort: '',
      icon: true,
      class: ['border border-transparent rounded-xl']
    },
    {
      label: '',
      prop: 'switch_actions',
      width: '15',
      headerAlign: 'center',
      align: 'center',
      class: []
    }
  ],
  options: {
    hasCheckboxColumn: true,
    deletedCount: 0,
    recovered: []
  },
  scrollTop: 0,
  // scrollCount: LIST_COUNT
})

const deleteCurrentSort = (arr, nameField, indexArray) => {
  arr = arr.map((el, index) => {
    if (el[nameField] && indexArray !== index) {
      el[nameField] = ''
    }
    return el
  })
}

const sortLinks = async ({ sortValue, prop, sortType }) => {
  if (sortValue === 'asc') {
    linksTable.value.list = tableData.value.list.sort((a, b) =>
      sortHelper.asc(a[prop], b[prop], sortType)
    )
  } else if (sortValue === 'desc') {
    linksTable.value.list = tableData.value.list.sort((a, b) =>
      sortHelper.desc(a[prop], b[prop], sortType)
    )
  } else {
    tableData.value.list = linksTable.value.list = await immutableLinks.value
      .slice()
      .map(generateLinks)
  }
}


const handleSort = async (idx) => {
  const { orderSort } = tableData.value.titles[idx]
  const { prop } = tableData.value.titles[idx]
  const { sortType } = tableData.value.titles[idx]
  const lock = 'users_count'
  let sortedList = []

  if (orderSort === 'asc') {
    tableData.value.titles[idx].orderSort = 'desc'
    deleteCurrentSort(tableData.value.titles, 'orderSort', idx)
    if (prop === 'userCount') {
      sortedList = tableData.value.list.sort((a, b) =>
        sortHelper.asc(a[lock], b[lock], sortType)
      )
      SORT_UNITS({ sortValue: 'asc', lock, sortType })
    } else {
      sortedList = tableData.value.list.sort((a, b) =>
        sortHelper.asc(a[prop], b[prop], sortType)
      )
      SORT_UNITS({ sortValue: 'asc', prop, sortType })
    }
  } else if (orderSort === 'desc') {
    tableData.value.titles[idx].orderSort = ''

    deleteCurrentSort(tableData.value.titles, 'orderSort', idx)
    if (prop === 'userCount') {
      SORT_UNITS({ sortValue: '', lock })
    } else {
      SORT_UNITS({ sortValue: '', prop })
    }
    switch (activeTab.value) {
      case sections.OBJECTS:
        objectsTable.value = JSON.parse(JSON.stringify(objectsTable.value))
        break
      case sections.GROUPS:
        groupsTable.value = JSON.parse(JSON.stringify(groupsTable.value))
        break
      case sections.ACCESS_LINKS:
        linksTable.value = JSON.parse(JSON.stringify(linksTable.value))
        break
      default:
        sortedList = searchedUnits.value.length
          ? searchedUnits.value
          : units.value
    }
  } else {
    tableData.value.titles[idx].orderSort = 'asc'

    deleteCurrentSort(tableData.value.titles, 'orderSort', idx)
    if (prop === 'userCount') {
      sortedList = tableData.value.list.sort((a, b) =>
        sortHelper.desc(a[lock], b[lock], sortType)
      )
      SORT_UNITS({ sortValue: 'desc', lock, sortType })
    } else {
      sortedList = tableData.value.list.sort((a, b) =>
        sortHelper.desc(a[prop], b[prop], sortType)
      )
      SORT_UNITS({ sortValue: 'desc', prop, sortType })
    }
  }

  tableData.value.list = sortedList
}

const handleClose = () => {
  RESET_SEARCH_UNITS()
  HANDLE_KEYSEARCH('')
  HANDLE_TYPESEARCH('')
  SET_ACTIVE_ELEMENT_SCROLL(null)
  emits('onClose')
}

const handleCreate = () => {
  emits('onCreate')
}

const handleCreateGroup = () => {
  emits('onCreateGroup')
}

const handleCreateLink = () => {
  emits('onCreateLink')
}

const handleEdit = (id) => {
  const isSelection = window.getSelection()
  if (isSelection && isSelection.type === 'Caret') {
    let unitId = id

    if (typeof id === 'object') {
      unitId = id.id
    }

    SET_ACTIVE_ELEMENT_SCROLL({
      tableScrollTop: getTableScrollTop(),
      count: defaultListCount.value
    })

    emits('onEdit', { id: unitId, tab: activeTab.value })
  }
}

const deletedIds = computed(() => deletedItems.value.map((item) => item.id))

const removeDeletedItemsFromList = async () => {
  let filteredList = ref()
  switch (activeTab.value) {
    case sections.OBJECTS:
      filteredList.value = objectsTable.value.list.filter((item) => {
        const deletedIdsLocal = deletedItems.value.map((item) => item.id)
        if (deletedIdsLocal.value) {
          const isDeleted = deletedIdsLocal.value.includes(item.id)
          return !isDeleted
        }
        return false
      })
      objectsTable.value.list = filteredList.value
      break
    case sections.GROUPS:
      groupsTable.value.list = groupsTable.value.list.filter((item) => {
        const isDeleted = deletedIds.value.includes(item.id)
        return !isDeleted
      })
      break
    case sections.ACCESS_LINKS:
      filteredList.value = linksTable.value.list.filter((item) => {
        if (deletedIds.value.length > 0 && deletedIds.value[0] === undefined) {
          const isDeleted = deletedItems.value.includes(item.id)
          return !isDeleted
        } else {
          const isDeleted = deletedIds.value.includes(item.id)
          return !isDeleted
        }
      })
      linksTable.value.list = filteredList.value
      break
  }
}

const handleDelete = () => {
  if (deletedItems.value.length) {
    const tab = activeTab.value
    let delThis = deletedItems.value.map((item) => item.id)
    if (delThis[0] === undefined) {
      delThis = deletedItems.value
    }
    emits('onDelete', { tab, ids: delThis })
    removeDeletedItemsFromList()
    deletedItems.value.length = 0
  }
}

const handleSearch = async (data) => {
  HANDLE_KEYSEARCH(data.value)
  HANDLE_TYPESEARCH(data.field)

  const formData = {
    conditions: [data],
    fields: ['id']
  }

  switch (activeTab.value) {
    case sections.OBJECTS:
      searchObjects(formData)
      break
    case sections.GROUPS:
      searchGroups(data)
      break
    case sections.TRAILERS:
      searchTrailers(data)
      break
    case sections.ACCESS_LINKS:
      searchLinks(data)
      break
  }
}

const handleShowHistory = () => {
  let tabKey = 'units'

  switch (activeTab.value) {
    case sections.OBJECTS:
      tabKey = 'units'
      break
    case sections.GROUPS:
      tabKey = 'unitsgroups'
      break
    case sections.TRAILERS:
      tabKey = sections.TRAILERS
      break
    case sections.ACCESS_LINKS:
      tabKey = 'locators'
      break
  }

  emits('onShowHistory', { objects: tabKey, objectId: '' })
}

const onTabChange = async (name) => {
  setScrollTop()
  activeTab.value = name

  RESET_SEARCH_UNITS()
  HANDLE_KEYSEARCH('')
  HANDLE_TYPESEARCH('')
  if (name === sections.TRAILERS) return

  deletedItems.value.length = 0
  defaultListCount.value = tableData.value.scrollCount

  await getObjects(units.value)
  await getGroups(unitsGroup.value)
  document.querySelector('#table-id').scrollTo(0, tableData.value.scrollTop)
}

const disabled = ref(true)

const getTableScrollTop = () => {
  let tableId = '#table-id'
  if (activeTab.value === sections.TRAILERS) {
    tableId = '#table-id-trailers'
  }

  return document.querySelector(tableId).scrollTop
}

const setScrollTop = (value) => {
  const elementScroll = value || {
    tableScrollTop: getTableScrollTop(),
    count: defaultListCount.value
  }

  switch (activeTab.value) {
    case sections.OBJECTS:
      objectsTable.value.scrollTop = elementScroll.tableScrollTop
      objectsTable.value.scrollCount = elementScroll.count
      break
    case sections.GROUPS:
      groupsTable.value.scrollTop = elementScroll.tableScrollTop
      // groupsTable.value.scrollCount = elementScroll.count
      break
    case sections.TRAILERS:
      SET_SCROLL_TOP(elementScroll.tableScrollTop)
      break
    case sections.ACCESS_LINKS:
      linksTable.value.scrollTop = elementScroll.tableScrollTop
      // linksTable.value.scrollCount = elementScroll.count
      break
  }
}

const selectAll = (value) => {
  if (!value.length) return
  const valueIds = value.map((obj) => obj.id)
  switch (activeTab.value) {
    case sections.OBJECTS:
      deletedItems.value = []
      units.value.forEach((item) => {
        if (valueIds.includes(item.id)) {
          deletedItems.value.push(item)
        }
      })
      break
    case sections.GROUPS:
      deletedItems.value = []
      unitsGroup.value.forEach((item) => {
        if (valueIds.includes(item.id)) {
          deletedItems.value.push(item.id)
        }
      })
      break
    case sections.TRAILERS:
      deletedItems.value = allTrailers.value.map((item) => item.id)
      break
    case sections.ACCESS_LINKS:
      deletedItems.value = []
      deletedItems.value = locators.value.map((item) => item.id)
      break
  }
}

const selectRow = (value) => {
  disabled.value = !value.length
  if (value.length || deletedItems.value.length === unitsCount.value) {
    selectedData.value = value
  }
  deletedItems.value = value

  if (value.length === tableData.value.list.length) return
  linksTable.value.options.deletedCount = value.length
}

const searchObjects = async (formData) => {
  defaultListCount.value = LIST_COUNT

  await SEARCH_UNIT({ formData })

  const finalList = units.value.filter((unit) =>
    searchedUnits.value.some((el) => unit.id === el.id)
  )

  getObjects(finalList)

  scrollToTop()
}

const searchGroups = (formData) => {
  let searched = []

  if (formData.field === 'unit') {
    searched = unitsGroup.value.filter((group) => {
      const isExist = group.units.some((unit) =>
        searchHelper.search(unit.name, formData.value)
      )

      if (isExist) return group
    })
  } else {
    searched = unitsGroup.value.filter((group) =>
      searchHelper.search(group[formData.field], formData.value)
    )
  }

  SET_GROUPS_SEARCHED(searched)

  getGroups(searched)

  scrollToTop()
}

const searchTrailers = (formData) => {
  const searched = allTrailers.value.filter((trailer) =>
    searchHelper.search(trailer[formData.field], formData.value)
  )

  SET_TRAILERS_SEARCHED(searched)

  scrollToTop()
}

const searchLinks = (formData) => {
  let searched = []

  if (formData.field === 'unit') {
    searched = locators.value.filter((link) =>
      searchHelper.search(link[formData.field].name, formData.value)
    )
  } else if (formData.field === 'status') {
    searched = locators.value.filter((link) => {
      const { statusText } = checkLinkStatus(
        link.date_from,
        link.date_to,
        link.is_active
      )

      searchHelper.search(statusText, formData.value)
    })
  }

  SET_LINKS_SEARCHED(searched)

  getLinks(searched)

  scrollToTop()
}

const scrollToTop = () => {
  if (!listTable.value) return
  const table = document.querySelector('#table-id')

  table.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

const loadData = () => {
  let data = []

  switch (activeTab.value) {
    case sections.OBJECTS:
      data = getFilteredData(searchedUnits.value, units.value)
      break
    case sections.GROUPS:
      data = getFilteredData(searchedGroups.value, unitsGroup.value)
      break
    case sections.ACCESS_LINKS:
      data = getFilteredData(searchedLinks.value, locators.value)
      break
    default:
      data = units.value
      break
  }

  data = data
    .slice(defaultListCount.value, defaultListCount.value + LIST_COUNT)
    .map(generateObjects)

  data.forEach((item) => {
    objectsTable.value.list.push(item)
  })

  defaultListCount.value += LIST_COUNT
}

const getFilteredData = (searchedData, defaultData) => {
  return searchedData.length ? searchedData : defaultData
}

const generateLinks = (item) => {
  const { status, statusText } = checkLinkStatus(
    item.date_from,
    item.date_to,
    item.is_active
  )

  return {
    ...item,
    object: item.unit.name,
    status,
    statusText
  }
}

const generateGroups = (item) => {
  return {
    ...item,
    userCount: item.users.length,
    objects: item.units.length
  }
}

const generateObjects = (item) => {
  return {
    ...item,
    protocol: item.terminal_type.value,
    sim_1_2: [item.phonenumber, item.phonenumber2],
    settings: 'icon'
  }
}

const getObjects = async (list) => {
  const data = list.slice()
  objectsTable.value.list = await data
    .splice(0, objectsTable.value.scrollCount)
    .map(generateObjects)

  return objectsTable.value.list
}

const getGroups = async (list) => {
  const data = list.slice()
  groupsTable.value.list = await data
    .map(generateGroups)
    // .splice(0, groupsTable.value.scrollCount)
  return groupsTable.value.list
}

const checkLinkStatus = (from, to, active) => {
  const currentDateCompany = moment(formattedCurrentTime.value).valueOf()
  const fromDate = new Date(from).getTime()
  const toDate = new Date(to).getTime()
  let status = ''
  let statusText = ''

  if (toDate <= currentDateCompany) {
    status = linksStatus.INACTIVE
    statusText = $t(`new_units.links.status.${linksStatus.INACTIVE}`)
  } else if (
    fromDate < currentDateCompany &&
    currentDateCompany < toDate &&
    active
  ) {
    status = linksStatus.ACTIVE_ON
    statusText = $t(`new_units.links.status.${linksStatus.ACTIVE_ON}`)
  } else if (
    fromDate < currentDateCompany &&
    currentDateCompany < toDate &&
    !active
  ) {
    status = linksStatus.ACTIVE_OFF
    statusText = $t(`new_units.links.status.${linksStatus.ACTIVE_OFF}`)
  } else if (currentDateCompany < fromDate) {
    status = linksStatus.PLANNED
    statusText = $t(`new_units.links.status.${linksStatus.PLANNED}`)
  }

  return {
    status,
    statusText
  }
}

const changeLinkStatus = ({ item, value }) => {
  const link = locators.value.find((locator) => locator.id === item.id)

  link.is_active = value

  emits('onChangeLinkStatus', link)
}

const getLinks = async (list) => {
  const data = list.slice()
  linksTable.value.list = await data
    // .splice(0, defaultListCount.value)
    .map(generateLinks)
  return linksTable.value.list
}

const copySensors = (items) => {
  const newSensors = []
  items.forEach((val) => {
    if (val.sensors) {
      val.sensors.forEach((item) => {
        if (item.checked) {
          newSensors.push({ id: item.id })
        }
      })
    }
  })
  const units = deletedItems.value.map((val) => {
    return { id: val.id }
  })

  const data = {
    units,
    sensors: newSensors
  }

  objectsApi.copySensors(
    data,
    () => {
      showModalSensors.value = false
      GET_UNITS()
    },
    (error) => {
      eventBus.$showError(error.response.data.message)
    }
  )
}

const getCurrentTableSort = () => {
  let orderSort = ''
  let index = null
  tableData.value.titles.forEach((item, idx) => {
    if (item.orderSort) {
      orderSort = item.orderSort
      index = idx
    }
  })

  if (!orderSort) return null

  return {
    value: orderSort,
    idx: index
  }
}

watch(units, (newVal, oldVal) => {
  if (newVal.length >= oldVal.length) getObjects(newVal)
  groupsTable.value.list = newVal
  tableData.value.list = newVal
})

watch(unitsGroup, (newVal, oldVal) => {
  if (newVal.length >= oldVal.length) getGroups(newVal)
})

watch(locators, (newVal, oldVal) => {
  if (newVal.length >= oldVal.length) getLinks(newVal)
})

watch(
  () => props.loading,
  (val) => {
    if (!val && deletedItems.value.length) {
      removeDeletedItemsFromList()
      selectedData.value = []
      deletedItems.value = []
    }
  }
)

onMounted(async () => {
  if (activeElementScroll.value) {
    defaultListCount.value = activeElementScroll.value.count
    setScrollTop(activeElementScroll.value)
  }

  if (searchedUnits.value.length) {
    await getObjects(searchedUnits.value)
  } else {
    arr.value = await getObjects(units.value)
    await getObjects(units.value)
  }

  if (searchedGroups.value.length) {
    await getGroups(searchedGroups.value)
  } else {
    await getGroups(unitsGroup.value)
  }

  if (searchedLinks.value.length) {
    await getLinks(searchedLinks.value)
  } else {
    await getLinks(locators.value)
  }

  if (currentTableSortType.value) {
    const { idx, value } = currentTableSortType.value
    tableData.value.titles[idx].orderSort = value

    loadData()
  }

  document.querySelector('#table-id').scrollTo(0, tableData.value.scrollTop)
})

onBeforeUnmount(() => {
  SET_CURRENT_TABLE_SORT_TYPE(getCurrentTableSort())
})
</script>

<style lang="scss">
@import '../assets/styles/main.scss';

.disabled {
  pointer-events: none;
  path {
    fill: #a6aeb8;
  }
  rect {
    fill: #a6aeb8;
  }
}
.developing {
  position: relative;
  &:hover .developing__info {
    position: absolute;
    top: -10px;
    right: 25px;
    display: block;
    background: #dbebff;
    color: #2767b6;
    padding: 10px;
    border-radius: 12px;
    white-space: nowrap;
  }
  &__info {
    display: none;
  }
  &__icon {
    cursor: pointer;
  }
}
</style>
