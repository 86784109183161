<template>
  <div class="tabs relative z-40 py-4 px-5">
    <el-radio-group
      v-model="activeTab"
      class="objects-tabs"
      @change="changeTab"
    >
      <el-radio-button v-for="tab in tabs" :key="tab.label" :label="tab.name">
        <div class="flex justify-center items-center gap-2">
          <ErrorIcon v-if="errors.includes(tab.name)" />
          {{ tab.label }}
        </div>
      </el-radio-button>
    </el-radio-group>
    <div
      v-if="isObjectExist"
      class="objects-closed-tabs flex justify-center items-center gap-2 font-normal"
    >
      <LockIcon />
      {{ $t('new_units.objects.tabs.blocked_text') }}
    </div>
  </div>
</template>
<script setup>
import { computed, ref, watch } from 'vue'

import { useI18n } from '@/hooks/useI18n'
import { createVuexHelpers } from 'vue2-helpers'
import { validateSection } from '@/components/unitsNew/helpers/index.js'

import ErrorIcon from '@/components/unitsNew/assets/icons/error.vue'
import LockIcon from '@/components/unitsNew/assets/icons/lock.vue'

import sections from '@/enums/newUnits/objects-sections.js'

const $t = useI18n()

const { useGetters, useMutations } = createVuexHelpers()

const { activeObject, errors } = useGetters('units', ['activeObject', 'errors'])
const { SET_ERRORS } = useMutations('units', ['SET_ERRORS'])

const emits = defineEmits(['onChange'])

const props = defineProps({
  tab: {
    type: String,
    default: ''
  },
  isCurrent: {
    type: Boolean,
    default: false
  }
})

watch(
  () => props.isCurrent,
  (val) => {
    if (val) {
      activeTab.value = props.tab
    }
  }
)

const activeTab = ref(sections.BASIC)
const mainTabs = [
  {
    label: $t(`new_units.objects.tabs.${sections.BASIC}`),
    name: sections.BASIC
  },
  {
    label: $t(`new_units.objects.tabs.${sections.SENSORS}`),
    name: sections.SENSORS
  },
  {
    label: $t(`new_units.objects.tabs.${sections.PARAMETERS}`),
    name: sections.PARAMETERS
  }
]

const restTabs = [
  {
    label: $t(`new_units.objects.tabs.${sections.SHIFTS}`),
    name: sections.SHIFTS
  },
  {
    label: $t(`new_units.objects.tabs.${sections.SERVICE}`),
    name: sections.SERVICE
  },
  {
    label: $t(`new_units.objects.tabs.${sections.COUPLINGS}`),
    name: sections.COUPLINGS
  }
]

const tabs = computed(() => {
  const unit = activeObject.value

  if (unit) return unit.id ? mainTabs.concat(restTabs) : mainTabs

  return mainTabs
})

const isObjectExist = computed(() => {
  return activeObject.value ? !activeObject.value.id : true
})

const isCouplingsOrShifts = computed(() => {
  return (
    errors.value.length &&
    (props.tab === sections.SHIFTS ||
      props.tab === sections.COUPLINGS ||
      props.tab === sections.SENSORS)
  )
})

const changeTab = (newTab) => {
  const callback = (valid) => {
    if (valid && !errors.value.length) {
      SET_ERRORS([])
    }

    if (!valid || isCouplingsOrShifts.value) {
      activeTab.value = props.tab

      SET_ERRORS([props.tab])
    } else {
      emits('onChange', newTab)
    }
  }

  const isValidate = validateSection(props.tab, callback)

  if (!isValidate) emits('onChange', newTab)
}
</script>

<style lang="scss">
@import '../../assets/styles/UnitsObjectsTabs.scss';
</style>
